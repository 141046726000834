import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
    <Router>
        <App />
    </Router>
    
    {/* <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 100 100" preserveAspectRatio="xMinYMax" style={{}} class="bg">
        <defs>
            <pattern id="Text" x="0" y="0" width="350" height="75" patternTransform="rotate(-35)" patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse">
                <text x="10" y="50" class='background-text-1' >Summer</text>
                <text x="20" y="60" class='background-text-2' >Summer</text>
            </pattern>
            </defs>
        <rect id="rotated-text" fill="url(#Text)" width="100%" height="100%"/>
    </svg>     */}
</React.StrictMode>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function to
// log results (for example: reportWebVitals(console.log)) or send to an
// analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
