import React, {useState} from "react";
import {Icon, Menu} from "semantic-ui-react";
import { useHistory } from "react-router-dom";

function makeUI(index, alt, url, name, icon, type, history) {
    if (type == 'header') {
        return (
            <Menu.Header>{name}</Menu.Header>
        )
    } else if (type == 'link') {
        return(
            <Menu.Item index={index} title={alt} href={url} target="_blank">
                {name}
                {(() => {
                    if(icon != null) {
                        return <Icon name={icon} size='big'/>
                    }
                })()}
            </Menu.Item>
        )
    }
    else if (type == 'link') {
        return(
            <Menu.Item index={index} title={alt} href={url} target="_blank">
                {name}
                {(() => {
                    if(icon != null) {
                        return <Icon name={icon} size='big'/>
                    }
                })()}
            </Menu.Item>
        )
    }
    else {
        return(
            <Menu.Item index={index} title={alt} onClick={async () => {
                history.push(url);
            }}>
                {name}
            </Menu.Item>
        )
    }
}

export default function MenuItem(props) {
    const history = useHistory();

    const [name] = useState(props.name);
    const [alt] = useState(props.alt);
    const [url] = useState(props.url);
    const [index] = useState(props.index);
    const [icon] = useState(props.icon);
    const [type] = useState(props.type);

    return (
        makeUI(index, alt, url, name, icon, type, history)
    );
}