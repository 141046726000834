
if (window.location.hostname == 'localhost') {
    module.exports = 
    {
        API_URL: "localhost:3500",
        IMAGES_URL: "localhost:80"
    };
} else {
    module.exports = 
    {
        API_URL: "api.joeykessler.online",
        IMAGES_URL: "dynamicimages.joeykessler.online"
    };
}
  