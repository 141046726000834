import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Icon, Menu} from 'semantic-ui-react'
import MenuItem from "../Components/MenuItem";
import InternalMenuItem from "../Components/InternalMenuItem";
import CONFIG from "../config/config.js";

export default function LeftNavigation() {
    const [navigationLinks,
        setNavigationLinks] = useState([]);
    const [resumeLinks,
        setResumeLinks] = useState([]);
    const [jobHistoryEmployerList,
        setJobHistoryEmployerList] = useState([]);

    const history = useHistory();

    
    useEffect(() => {
        fetch('//'+CONFIG.API_URL+'/v1/updates/navigationLinks', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            let nl = JSON.parse(data).map((item, index) => (
                <MenuItem index={index} name={item.name} alt={item.alt} url={item.url} type={item.type}></MenuItem>
            ));
            setNavigationLinks(nl);
        })

        fetch('//'+CONFIG.API_URL+'/v1/updates/resumeLinks', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            let rl = JSON.parse(data).map((item, index) => (
                <MenuItem
                    index={index}
                    alt={item.alt}
                    url={item.url}
                    type={item.type}
                    icon={item.icon}></MenuItem >));
            setResumeLinks(rl);
        })
    
        fetch('//'+CONFIG.API_URL+'/v1/updates/jobHistoryEmployerList', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            let jhel = JSON.parse(data).map((item, index) => (
                <InternalMenuItem index={index} name={item.employer} alt={item.employer} url={item.employer}></InternalMenuItem>
            ));
            setJobHistoryEmployerList(jhel);
        })
    }, [])

    return ( 
        <> 
        <Menu fluid vertical>
        <Menu.Item>
            <Menu.Menu>
                <Menu.Item>
                    <Menu.Header>Job History</Menu.Header>
                    {jobHistoryEmployerList}
                </Menu.Item>
            </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
            <Menu.Menu>
                <Menu.Item>
                    {navigationLinks}
                </Menu.Item>
            </Menu.Menu>
        </Menu.Item>
    </Menu> <div className = 'header menu-not-header'>Resume</div>
    <Menu fluid widths={resumeLinks.length} > 
        {resumeLinks} 
    </Menu>
    </>);
}