import 'semantic-ui-css/semantic.min.css';
import './style/global.css';
import './style/responsive-semantic.css';
import {
    Button,
    Container,
    Grid,
    Header
} from 'semantic-ui-react'
import {Route, useHistory, Switch} from "react-router-dom";
// import Home from "./views/Home";
import Cache from "./views/Cache";
import NotFound from "./views/NotFound";
import LeftNavigation from "./views/LeftNavigation";
import TopNavigation from "./views/TopNavigation";
import JobHistory from './views/JobHistory';
import Reads from './views/Reads';
import DynamicImages from './views/DynamicImages';

function App() {
    const history = useHistory();

    return (
        <Container>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <Button size="medium" floated="right" color="blue" inverted style={{marginTop: '1em'}} className="top-nav">
                                <TopNavigation></TopNavigation>
                            </Button>
                            <Header as="h1" className="title" onClick={async () => {
                                history.push("/");
                            }}>Joey Kessler</Header>
                            <Header as="h4" className="title">Leader, Inventor, Technologist</Header>
                        </Grid.Column>
                    </Grid.Row>
                     <Grid.Row columns={2}>
                        <Grid.Column width={4} className="mobile hidden tablet hidden" >
                            <LeftNavigation></LeftNavigation>
                        </Grid.Column>
                        <Grid.Column tablet={16} computer={12}>
                            <Container className='main-container'>
                                <Switch>
                                    {/* <Route exact path="/" component={Home}/> */}
                                    <Route exact path="/" component={Reads}/>
                                    <Route exact path="/reads/:book?" component={Reads}/>
                                    <Route path="/cache" component={Cache}/>
                                    <Route path="/dynamic-images" component={DynamicImages}/>
                                    <Route path="/jobhistory/:employer" component={JobHistory}/>
                                    <Route component={NotFound}/>
                                </Switch>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
        </Container>
    );
}

export default App;
