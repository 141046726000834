import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Dropdown, Icon} from 'semantic-ui-react';
import CONFIG from "../config/config.js";

const emptyNav = [{"name":"","alt":"","url":""}]
const emptyResume = [{"name":"","alt":"","url":"","icon":""}];
const emptyEmployer = [{"employer":"","startDate":"","endDate":"","location":[],"url":[],"description":[],"tags":[],"imageUrl":""}]

function makeUI(index, alt, url, name, icon, type, history) {
    if (type == 'header') {
        return (
            <Dropdown.Header>{name}</Dropdown.Header>
        )
    } else if (type == 'link') {
        return(
            <Dropdown.Item 
                as="a"
                index={index} 
                link="true"
                title={alt} 
                href={url}
                target="_blank"
                rel="noreferrer"
                >{name}</Dropdown.Item>
        )
    } else {
        return(
            <Dropdown.Item 
                index={index}
                link="true"
                title={name}
                onClick={async() => {
                    history.push(url);
                }}
                >{name}</Dropdown.Item>
        )
    }
}

export default function TopNavigation() {

    const [navigationLinks,
        setNavigationLinks] = useState([]);
    const [resumeLinks,
        setResumeLinks] = useState([]);
    const [jobHistoryEmployerList,
        setJobHistoryEmployerList] = useState([]);

    const history = useHistory();

    useEffect(() => {
        fetch('//'+CONFIG.API_URL+'/v1/updates/navigationLinks', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            let nl = JSON.parse(data).map((item, index) => (
                makeUI(item.index, item.alt, item.url, item.name, item.icon, item.type, history)
            ))
            setNavigationLinks(nl);
        })

        fetch('//'+CONFIG.API_URL+'/v1/updates/resumeLinks', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            let rl = JSON.parse(data).map((item, index) => (
                <Dropdown.Item
                    index={index}
                    title={item.alt}
                    href={item.url}
                    link="true"
                    target="_blank"
                    rel="noreferrer"
                    ><Icon name={item.icon}></Icon>{item.name}</Dropdown.Item >
            ))
            setResumeLinks(rl);
        })
    
        fetch('//'+CONFIG.API_URL+'/v1/updates/jobHistoryEmployerList', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            let jhel = JSON.parse(data).map((item, index) => (
                <Dropdown.Item 
                    index={index}
                    link="true"
                    title={item.employer}
                    onClick={async() => {
                        let url = "/jobHistory/" + item.employer;
                        history.push(url);
                    }}
                    >{item.employer}</Dropdown.Item>
                ));
            setJobHistoryEmployerList(jhel);
        })
    }, [])
    
    return ( 
    <> 
        <Dropdown item icon="bars" >
            <Dropdown.Menu direction="left">
                <Dropdown.Header>Job History</Dropdown.Header>
                {jobHistoryEmployerList}
                <Dropdown.Divider/>
                {navigationLinks}
            <Dropdown.Divider/>

            <Dropdown.Header>Resume</Dropdown.Header>
            {resumeLinks}
        </Dropdown.Menu>
    </Dropdown> 
    </>);
}