import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Button, Card, Container, Header, Icon} from "semantic-ui-react";
import CONFIG from "../config/config.js";

export default function Cache() {
    const history = useHistory();
    const [ui, setUI] = useState([]);
    const [reads, setReads] = useState(false);
    const [jobs, setJobs] = useState(false);
    const [employers, setEmployers] = useState(false);
    const [resumeLinks, setResumeLinks] = useState(false);
    const [navLinks, setNavLinks] = useState(false);

function getAllData() {
    getNavLinks();
    getResumeLinks();
    getEmployers();
    getJobs();
    getReads();
}

function getNavLinks() {
    setNavLinks(true);
    fetch('//'+CONFIG.API_URL+'/v1/updates/navigationLinks?forcereload=true', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
    .then(res => setNavLinks(false))
}

function getResumeLinks() {
    setResumeLinks(true);
    fetch('//'+CONFIG.API_URL+'/v1/updates/resumeLinks?forcereload=true', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
    .then(res => setResumeLinks(false))
}

function getEmployers() {
    setEmployers(true);
    fetch('//'+CONFIG.API_URL+'/v1/updates/jobhistoryEmployerList?forcereload=true', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
    .then(res => setEmployers(false))
}

function getJobs() {
    setJobs(true);
    fetch('//'+CONFIG.API_URL+'/v1/updates/jobhistoryJobList?forcereload=true', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => setJobs(false))
}

function getReads() {
    setReads(true);
    fetch('//'+CONFIG.API_URL+'/v1/updates/readsList?forcereload=true', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => setReads(false))
}

function makeUI() {
    let ui = () => (
            <Card.Group className='setup-cards' itemsPerRow={2} stackable>
                <Card 
                    fluid 
                    color='blue' 
                    style={{
                        width: '200%'
                    }}>
                    <Card.Content>
                        <i className="sync alternate"></i>
                        <Card.Header>Refresh all Data</Card.Header>
                        <Card.Description>Will pull latest data from all the google documents.</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button
                            icon
                            labelPosition="right"
                            floated="right"
                            color="blue"
                            onClick={async() => {
                            getAllData();
                        }}>
                            Refresh Data
                            <Icon name="refresh" />
                        </Button>
                        
                    </Card.Content>
                </Card>
                <Card>
                    <Card.Content>
                        <i className="sync alternate"></i>
                        <Card.Header>Navigation Links Refresh</Card.Header>
                        <Card.Meta>
                            <a
                                href="https://docs.google.com/spreadsheets/d/1OZKnYn5BzTgoiNuWyhZbik4Ek3luyb8VO9_W2t0lBO4/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer">Google Doc</a>
                        </Card.Meta>
                        <Card.Description>Will pull latest data from google document.</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button
                            icon
                            labelPosition="right"
                            className={`${navLinks ? "showload" : ""}`}
                            floated='right'
                            color='blue'
                            onClick={async() => {
                                getNavLinks();
                            }}>
                            Refresh Data
                            <Icon name="refresh" className={`${navLinks ? "loading" : ""}`} />
                        </Button>
                    </Card.Content>
                </Card>

                <Card>
                    <Card.Content>
                        <i className="sync alternate"></i>
                        <Card.Header>Resume Links Refresh</Card.Header>
                        <Card.Meta>
                            <a
                                href="https://docs.google.com/spreadsheets/d/1OZKnYn5BzTgoiNuWyhZbik4Ek3luyb8VO9_W2t0lBO4/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer">Google Doc</a>
                        </Card.Meta>
                        <Card.Description>Will pull latest data from google document.</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button
                            icon
                            labelPosition="right"
                            className={`${resumeLinks ? "showload" : ""}`}
                            floated='right'
                            color='blue'
                            onClick={async() => {
                                getResumeLinks();
                            }}>
                            Refresh Data
                            <Icon name="refresh" className={`${resumeLinks ? "loading" : ""}`} />
                        </Button>
                    </Card.Content>
                </Card>

                <Card>
                    <Card.Content>
                        <i className="sync alternate"></i>
                        <Card.Header>Job History - Employer List Refresh</Card.Header>
                        <Card.Meta>
                            <a
                                href="https://docs.google.com/spreadsheets/d/1OZKnYn5BzTgoiNuWyhZbik4Ek3luyb8VO9_W2t0lBO4/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer">Google Doc</a>
                        </Card.Meta>
                        <Card.Description>Will pull latest data from google document.</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button
                            icon
                            labelPosition="right"
                            className={`${employers ? "showload" : ""}`}
                            floated='right'
                            color='blue'
                            onClick={async() => {
                                getEmployers();
                            }}>
                            Refresh Data
                            <Icon name="refresh" className={`${employers ? "loading" : ""}`} />
                        </Button>
                    </Card.Content>
                </Card>

                <Card>
                    <Card.Content>
                        <i className="sync alternate"></i>
                        <Card.Header>Job History - Job List Refresh</Card.Header>
                        <Card.Meta>
                            <a
                                href="https://docs.google.com/spreadsheets/d/1OZKnYn5BzTgoiNuWyhZbik4Ek3luyb8VO9_W2t0lBO4/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer">Google Doc</a>
                        </Card.Meta>
                        <Card.Description>Will pull latest data from google document.</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button
                            icon
                            labelPosition="right"
                            className={`${jobs ? "showload" : ""}`}
                            floated='right'
                            color='blue'
                            onClick={async() => {
                                getJobs();
                            }}>
                            Refresh Data
                            <Icon name="refresh" className={`${jobs ? "loading" : ""}`} />
                        </Button>
                    </Card.Content>
                </Card>
                <Card>
                    <Card.Content>
                        <i className="sync alternate"></i>
                        <Card.Header>Reads - Reads page data refresh</Card.Header>
                        <Card.Meta>
                            <a
                                href="https://docs.google.com/spreadsheets/d/1OZKnYn5BzTgoiNuWyhZbik4Ek3luyb8VO9_W2t0lBO4/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer">Google Doc</a>
                        </Card.Meta>
                        <Card.Description>Will pull latest data from google document.</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button
                            icon
                            labelPosition="right"
                            className={`${reads ? "showload" : ""}`}
                            floated="right"
                            color="blue"
                            onClick={async() => {
                                getReads();
                            }}>
                            Refresh Data
                            <Icon name="refresh" className={`${reads ? "loading" : ""}`} />
                        </Button>
                    </Card.Content>
                </Card>
            </Card.Group>
        )
    setUI(ui);

}

useEffect(() => {
    makeUI();
}, [reads, employers, jobs, navLinks, resumeLinks])
    return (
        <Container id="setup">
            <Header as='h1'>Cache Management</Header>
            <Header as='h3'>Links to refresh data from linked google documents.</Header>
            <p>This site stores all it's data in a google spreadsheet.  That spreadsheet is read as a .csv file and converted to .json. Those .json files are stored on the backend companion site, api.joeykessler.online.  This page will allow you to refresh the static files.  This lets me modify my site from anywhere I can reach google docs.</p>
            {ui}
        </Container>
    );
}
