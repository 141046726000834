import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { Menu} from "semantic-ui-react";

export default function MenuItem(props) {
    const [name] = useState(props.name);
    const [alt] = useState(props.alt);
    const [url] = useState(props.url);
    const [index] = useState(props.index);
    
    const history = useHistory();

    return (
        <Menu.Item index={index} title={alt} onClick={async () => {
            history.push("/jobhistory/" + name);
          }}>
            {name}
        </Menu.Item>
    );
}