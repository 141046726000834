import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Card, Label} from "semantic-ui-react";
import Moment from 'react-moment';

var moment = require('moment');

function dateDiff(s,e) {
    let totalMonths = moment(s).diff(moment(e), 'months', true);
    let years = Math.floor(totalMonths / 12);
    let months = Math.floor(totalMonths % 12);
    let yearString = '';
    let monthString = '';

    if (years == 1) {
        yearString = '1 year '
    } else if (years > 1) {
        yearString = years + ' years '
    }
    
    if (months == 1) {
        monthString = '1 month'
    } else if (months > 1) {
        monthString = months + ' months'
    }

    return  yearString + monthString;
}

export default function Job(props) {
    const empty = {
        "employer": "",
        "title": "",
        "startDate": "",
        "endDate": "",
        "organization": "",
        "description": [],
        "tags": []
    };

    const history = useHistory();
    const {employer} = useParams();
    const [employerData, setEmployerData] = useState(empty);
    const [jobData, setJobData] = useState(props.data);
    
    return (
            <Card fluid>
                <Card.Content>
                    <Card.Header className="card-job-title">{jobData.title}</Card.Header>
                    <Card.Header className="card-subitle">{jobData.organization}</Card.Header>
                    <Card.Meta>
                        <Moment format="MMMM YYYY">{jobData.startDate}</Moment>- <Moment format="MMMM YYYY">{jobData.endDate}</Moment>
                    </Card.Meta>
                    <Card.Meta>
                        {dateDiff(jobData.endDate, jobData.startDate)}
                    </Card.Meta>
                
                </Card.Content>
                <Card.Content>
                    <Card.Header className="card-subitle">Overview</Card.Header>
                    {jobData.description.map((item, index) => (
                        <p>{item}</p>
                    ))}
                    <Card.Header className="card-subitle" style={{marginTop: "1em"}}>Highlights</Card.Header>
                    <ul>
                        {jobData.items.map((item, index) => (
                            <li>{item}</li>
                        ))}
                    </ul>
                    {jobData.links.map((item, index) => (
                        <a className="job-links" rel="noopener" title={item.title} href={item.url} target="_blank">{item.name}</a>
                    ))}
                </Card.Content>
                <Card.Content extra>
                {jobData.tags.map((item, index) => (
                        <Label color='blue' size='mini'>{item}</Label>
                    ))}
                </Card.Content>
            </Card>
    );
}
