import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {Button, Card, Container, Grid, Header, Icon, Label, Rating} from "semantic-ui-react";
import CONFIG from "../config/config.js";


export default function Reads() {
    const history = useHistory();
    const [reads,
        setReads] = useState([]);
    const {book} = useParams();

    function makeLabel(i) {
        if (i.indexOf("icon") !== -1) {
            return (
                ReactHtmlParser('<i class="'+i+'"></i>')
                )
        } else if (i.indexOf("rating") !== -1) {
            // get rating
            let rating = i.split(" ")[1];
            let ratingHtml = "";
            for (var i = 0; i < 5; i++) {
                if(rating > i) {
                    ratingHtml += '<i class="icon star yellow"></i>';
                } else {
                    ratingHtml += '<i class="icon star black"></i>';
                }
            }
            return ReactHtmlParser(ratingHtml)
        } else {
            return i
        }
    }

    useEffect(() => {
        fetch('//'+CONFIG.API_URL+'/v1/updates/readsList', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            let w = JSON.parse(data).map((item, index) => (
                <Card fluid className={`${book != undefined && book != item.title ? "hidden" : ""}`}>
                    <Card.Content>
                        <Button icon labelPosition='right' floated="right" color="blue" title={'Read "' + item.title + '"'} 
                            className={`${book == item.title ? "hidden" : ""}`}
                            onClick={async () => {
                                history.push("/reads/" + item.title);
                            }}>
                            More
                            <Icon name="right arrow" />
                        </Button>
                        <Button icon labelPosition='right' floated="right" color="blue" title={'Read "' + item.title + '"'} 
                            className={`${book == item.title ? "" : "hidden"}`}
                            onClick={async () => {
                                history.push("/reads");
                            }}>
                            Back
                            <Icon name='left arrow' />
                        </Button>
                        
                        <Card.Header>{item.title}</Card.Header>
                        <Card.Header className="card-subitle">{item.subtitle}</Card.Header>
                        <Card.Meta style={{marginTop: '5px'}}>{item.meta}</Card.Meta>
                        <Card.Meta style={{marginTop: '2px'}}>{item.date}</Card.Meta>
                    </Card.Content>
                    <Card.Content className={`${item.title == book ? "words" : "hidden words"}`}>
                        {ReactHtmlParser(item.content)}
                    </Card.Content>
                    <Card.Content extra>
                        { item.tags.map((item, index) => (
                                <Label size='mini'>{makeLabel(item)}</Label>
                            )) }
                    </Card.Content>
                </Card>
            ));
            setReads(w);
        })

    }, [book])

    return (
        <Container id="words">
            <Header as='h1'>Reads</Header>
            <Header className="card-subitle">Some things I'm reading</Header>
            <p>I read a lot, sometimes for fun, sometimes for work, sometimes for both.</p>
            {reads}
        </Container>
    );
}
