import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Card, Container, Header, Item, Image, Label} from "semantic-ui-react";
import Job from '../Components/Job';
import CONFIG from "../config/config.js";

import Moment from 'react-moment';
var moment = require('moment');


function dateDiff(s,e) {
    let totalMonths = moment(s).diff(moment(e), 'months', true);
    let years = Math.floor(totalMonths / 12);
    let months = Math.floor(totalMonths % 12);
    let yearString = '';
    let monthString = '';

    if (years == 1) {
        yearString = '1 year '
    } else if (years > 1) {
        yearString = years + ' years '
    }
    
    if (months == 1) {
        monthString = '1 month'
    } else if (months > 1) {
        monthString = months + ' months'
    }

    return  yearString + monthString;
}

function getDataEmployer(whom,dat) {
    for (var i = 0; i < dat.length; i++) {
        let item = dat[i];
        if (item.employer == whom) {
            return JSON.parse(JSON.stringify(item));
        }
    }
    return {};
}

function getDataJob(whom, dat) {
    const item = dat.filter( x => 
        x.employer == whom
    );
    return JSON.parse(JSON.stringify(item));
}

export default function JobHistory() {
    const empty = {
        "employer": "",
        "startDate": "",
        "endDate": "",
        "location": [],
        "url": [],
        "description": [],
        "tags": [],
        "imageUrl": ""
    };
    const emptyJob = [];

    const history = useHistory();
    const {employer} = useParams();
    const [employerData, setEmployerData] = useState([]);
    const [jobData, setJobData] = useState([]);
    const [employerLocationData, setEmployerLocationData] = useState([]);
    const [employerURLData, setEmployerURLData] = useState([]);
    const [employerDescriptionData, setEmployerDescriptionData] = useState([]);
    const [employerTagData, setEmployerTagData] = useState([]);
            
    useEffect(() => {

        fetch('//'+CONFIG.API_URL+'/v1/updates/jobHistoryEmployerList', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            
            let eData = getDataEmployer(employer, JSON.parse(data));
            if (eData.endDate == 'today') {
                eData.endDate = new Date();
            }

            let eld = eData.location.map((item, index) => (
                <Card.Meta>
                    {item}
                </Card.Meta>
            ))

            let eud = eData.url.map((item, index) => (
                <Card.Description>
                    <a rel="noopener" title={item.title} href={item.url} target="_blank">{item.name}</a>
                </Card.Description>
            ))

            let edd = eData.description.map((item, index) => (
                <p>{item}</p>
            ))

            let etd = eData.tags.map((item, index) => (
                <Label color='blue' size='mini'>{item}</Label>
            ))
            setEmployerData(eData);
            setEmployerLocationData(eld);
            setEmployerURLData(eud);
            setEmployerDescriptionData(edd);
            setEmployerTagData(etd);
        })


        fetch('//'+CONFIG.API_URL+'/v1/updates/jobHistoryJobList', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then( resp => resp.json())
        .then((data)=> {
            let jData = getDataJob(employer, JSON.parse(data));

            jData = jData.map(obj =>
            obj.endDate === 'today'
              ?  { ...obj, endDate: new Date() }
              : obj
          );

            let jobs = jData.map((item, index) => (
                <Job data={item} index={index}></Job>
            ))
            setJobData(jobs);
        })

        

    }, [employer])

    return (
        <Container id="job-history">
            <Header as='h1'>Job History</Header>
            <Card fluid>
                <Card.Content>
                    <Image floated='right' size='tiny' src={'/'+employerData.imageUrl}/>
                    <Card.Header>{employer}</Card.Header>
                    <Card.Meta>
                        <Moment format="MMMM YYYY">{employerData.startDate}</Moment>- <Moment format="MMMM YYYY">{employerData.endDate}</Moment>
                    </Card.Meta>
                    <Card.Meta>
                        {dateDiff(employerData.endDate, employerData.startDate)}
                    </Card.Meta>
                    {employerLocationData}
                    {employerURLData}
                </Card.Content>
                <Card.Content>
                {employerDescriptionData}
                </Card.Content>
                <Card.Content extra>
                {employerTagData}
                </Card.Content>
                <Card.Content>
                    {jobData}
                </Card.Content>
            </Card>
        </Container>
    );
}
