import React from "react";
import {useHistory} from "react-router-dom";
import {Card, CardGroup, Container, Image, Icon} from "semantic-ui-react";
import CONFIG from "../config/config.js";

export default function NotFound() {
    const history = useHistory();

    return (
        <Container id="home">
            <CardGroup centered >
            <Card style={{width: '80%'}}>
                    <Image
                        src={'//' + CONFIG.IMAGES_URL + '/v1/image/man?format=svg&random'+ Math.random()}
                        wrapped
                        ui={false}/>
                    <Card.Content>
                        <Card.Header>Curtis</Card.Header>
                        <Card.Meta>
                            <span className='date'>Virtual Customer Advocate</span>
                        </Card.Meta>
                        <Card.Description>
                            Curtis thinks you might be confused, there is nothing here
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <a href="/" rel="noreferrer">Go home</a>
                    </Card.Content>
                </Card>
            </CardGroup>

        </Container>
    );
}
