import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    Button,
    Card,
    CardGroup,
    Container,
    Dropdown,
    Header,
    Image,
    Grid,
    Menu
} from "semantic-ui-react";
import config from "../config/config.js";
import CONFIG from "../config/config.js";

export default function DynamicImages() {
    const history = useHistory();
    const [tagSelectUI, setTagSelectUI] = useState([]);
    const [imageSelectUI, setImageSelectUI] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [data, setData] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const [imagePath, setImagePath] = useState([(<Image
        src={"//"+CONFIG.IMAGES_URL+"/v1/image/man?format=svg"}
        wrapped
        ui={false}/>)]);
    
    const buildTagUI = () => {
        let sTags = selectedTags;
        let dd = tagList.map((item, index) => (
            <Button size="mini" className={`${sTags.indexOf(item) > -1 ? "orange tag-buttons" : "blue tag-buttons"}`}
                onClick={async () => {
                    let st = selectedTags;
                    if (st.indexOf(item) == -1) {
                        st.push(item);
                    } else {
                        st.splice(st.indexOf(item), 1)
                    }
                    buildTagUI()
                }}>{item}</Button>
        ))
        setTagSelectUI(dd);
    }

    const makeDropdown = () => {
        let dd = data.map((item, index) => (
            <Dropdown.Item text={item.image} data-tags={item.tags} onClick={async () => {
                setTagList(item.tags);
                setSelectedImage(item.image);
              }} />
        ))
        setImageSelectUI(dd);
    }

    const fetchImage = async () => {
        let img = '//'+CONFIG.IMAGES_URL+'/v1/image/' + selectedImage + '?format=svg&tags=' + selectedTags + '&useLooseTagMatch=false&rand=' + Math.random();// + looseMatch;
        
        setImagePath((<Image
            src={img}
            wrapped
            />));
    };

    useEffect(() => {
        buildTagUI();
    }, [tagList])

    useEffect(() => {
        makeDropdown()
    }, [data])

    useEffect(() => {
        fetch('//' + CONFIG.IMAGES_URL + '/v1/image/', {})
            .then(resp => resp.json())
            .then((data) => {
                setData(data);
                makeDropdown();
            })
    }, [])
    return (
        <Container id="home">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h3">Image Select</Header>
                        <Button.Group color="blue" floated="left">
                            <Button>{selectedImage}</Button>
                            <Dropdown
                                className="button icon"
                                floating
                                >
                                <Dropdown.Menu>
                                    {imageSelectUI}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h3">Available Tags</Header>
                        {tagSelectUI}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button fluid size="massive" color="orange" onClick={async () => {
                            fetchImage()
                      }}>Get that Image</Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <CardGroup centered >
                            <Card style={{width: '60%'}}>
                                {imagePath}
                            </Card>
                        </CardGroup>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            
            

        </Container>
    );
}
